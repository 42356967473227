import React, { useEffect, useState } from 'react'
import CreateButton from '../shared/CreateButton'
import FormInput from '../shared/FormInput'
import SaveFixedButton from '../shared/SaveFixedButton'
import ModalAlert from '../shared/ModalAlert'
import MediaUploader from '../shared/MediaUploader'
import MediaGallery from '../shared/MediaGallery'
import Toggle from 'react-toggle'
import moment from 'moment'
import MomentUtils from '@date-io/moment'
import { MuiPickersUtilsProvider, TimePicker } from '@material-ui/pickers'
import VerticalDraggableItems from '../shared/VerticalDraggableItems'

const dayArray = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday'
]

export default function EditPromoContent({
  appProps, assetOnMerchantAccount, canEditSubaccounts, item, mediaPath, merchantSubaccounts, terminals, meraccTerminals, updatePath
}) {
  const [promoContent, setPromoContent]       = useState(item)
  const [mediaUploader, setMediaUploader]     = useState(null)
  const [showUploadAlert, setShowUploadAlert] = useState(false)
  const [saveButtonRef, setSaveButtonRef]     = useState(null)

  useEffect(() => {
    setPromoContent(prevState => ({
      ...prevState,
      schedules: formatSchedules(prevState.schedules)
    }))
  }, [])

  const formatSchedules = schedules => {
    return schedules.map(el => ({
      ...el, intervals: el.intervals.map(item => ({
        ...item,
        from: moment(new Date(`2000-01-01 ${ item.from }`)),
        to: moment(new Date(`2000-01-01 ${ item.to }`))
      }))
    }))
  }

  const handleChange = (key, e) => {
    let { value } = e.target

    if (key === 'rotationPeriod') {
      if (+value === 0 && value !== '0') {
        value = promoContent.rotationPeriod
      } else {
        value = parseInt(value)
        value = value >= 0 ? value : 0
      }
    } else if (key === 'promoAfterInactivityMinutes') {
      if (+value === 0 && value !== '0') {
        value = promoContent.promoAfterInactivityMinutes
      } else {
        value = parseInt(value)
        value = value >= 0 ? value : 0
      }
    } else if (key === 'merchantSubaccounts') {
      setPromoContent(prevState => ({
        ...prevState,
        terminals: prevState.terminals.filter(item => terminals.find(el => el.id === item && value.includes(el.merchantSubaccountId)))
      }))
    }

    setPromoContent(prevState => ({
      ...prevState,
      [key]: value
    }))

    saveButtonRef.setSaveableStatus()
  }

  const setAssets = assets => {
    setPromoContent(prevState => ({
      ...prevState,
      assets: assets
    }))

    saveButtonRef.setSaveableStatus()
  }

  const handleFileDrag = items => {
    setPromoContent(prevState => ({
      ...prevState,
      assets: items.map(el => ({
        ...el,
        id: el.id.split('-')[0] ? +el.id.split('-')[0] : undefined
      }))
    }))

    saveButtonRef.setSaveableStatus()
  }

  const handleUploadAsset = uploadedFile => {
    setPromoContent(prevState => {
      let updatedAssets = [...prevState.assets]
      const assetIndex = updatedAssets.findIndex(asset => asset.uid === uploadedFile.uploadUid)
      if (assetIndex >= 0) {
        updatedAssets.splice(assetIndex, 1, { ...uploadedFile, blob: null })
      }

      return {
        ...prevState,
        assets: updatedAssets
      }
    })
  }

  const handleScheduleDefaultToggle = checked => {
    if (checked === false) {
      const updatedSchedules = [...promoContent.schedules]

      dayArray.forEach((day, idx) => {
        const relevantScheduleIndex = promoContent.schedules.findIndex(el => el.day === day)

        if (relevantScheduleIndex > -1) {
          updatedSchedules[relevantScheduleIndex]._destroy = false
        } else {
          updatedSchedules.push({
            id: -idx,
            day: day,
            intervals: [
              {
                from: moment('2000-01-01 00:00','YYYY-MM-DD h:mm a'),
                to:   moment('2000-01-01 23:59','YYYY-MM-DD h:mm a')
              }
            ]
          })
        }
      })

      setPromoContent(prevState => ({ ...prevState, schedules: updatedSchedules }))
    } else {
      setPromoContent(prevState => ({
        ...prevState,
        schedules: prevState.schedules.map(el => ({ ...el, _destroy: true }))
      }))
    }

    saveButtonRef.setSaveableStatus()
  }

  const handleScheduleDayToggle = (day, checked) => {
    setPromoContent(prevState => ({
      ...prevState,
      schedules: prevState.schedules.map(el => el.day === day ? { ...el, active: checked } : el)
    }))

    saveButtonRef.setSaveableStatus()
  }

  const handleTimeChange = (time, schedule, interval, intervalIndex, key) => {
    const relevantScheduleIndex = promoContent.schedules.findIndex(el => el.id === schedule.id)

    setPromoContent(prevState => ({
      ...prevState,
      schedules: [
        ...prevState.schedules.slice(0, relevantScheduleIndex),
        {
          ...prevState.schedules[relevantScheduleIndex],
          intervals: [
            ...prevState.schedules[relevantScheduleIndex].intervals.slice(0, intervalIndex),
            {
              ...interval,
              [key]: time
            },
            ...prevState.schedules[relevantScheduleIndex].intervals.slice(intervalIndex + 1),
          ]
        },
        ...prevState.schedules.slice(relevantScheduleIndex + 1),
      ]
    }))

    saveButtonRef.setSaveableStatus()
  }

  const renderScheduleIntervals = schedule => {
    return (
      <div>
        { schedule.intervals.map((interval, index) => (
          <div
            key={ index }
            className={ `flex-box items-center content-space-between p-l-5 ${ index === schedule.intervals.length - 1 ? 'm-b-20' : 'm-b-10' }` }
          >
            <MuiPickersUtilsProvider
              utils={ MomentUtils }
              locale={ 'en' }
            >
              <div className='flex-box gap-2'>
                <TimePicker
                  label={ Website.translations.from }
                  timezone="Europe/Athens"
                  value={ typeof interval.from === 'object' ? moment(interval.from) : moment(new Date(`2000-01-01 ${ interval.from }`)) }
                  onChange={ time => handleTimeChange(time, schedule, interval, index, 'from') }
                />

                <TimePicker
                  label={ Website.translations.to }
                  timezone="Europe/Athens"
                  value={ typeof interval.to === 'object' ? moment(interval.to) : moment(new Date(`2000-01-01 ${ interval.to }`)) }
                  onChange={ time => handleTimeChange(time, schedule, interval, index, 'to') }
                />
              </div>
            </MuiPickersUtilsProvider>

            <div className='flex-box items-center content-center gap-2'>
              { schedule.intervals.length > 1
                ? <div
                    className="btn plain"
                    style={{ padding: 0 }}
                    onClick={ _e => deleteTimeRange(schedule.id, index) }
                  >
                    <i className="fa-light fa-trash" />
                  </div>
                : <div className='flex-1'></div>
              }


              { index === schedule.intervals.length - 1
                ? <div
                    className="btn plain"
                    style={{ padding: 0 }}
                    onClick={ _e => addTimeRange(schedule.id) }
                  >
                    <i className="fa-light fa-circle-plus" />
                  </div>
                : <div className='flex-1'></div>
              }
            </div>
          </div>
        ))}
      </div>
    )
  }

  const addTimeRange = scheduleId => {
    const relevantScheduleIndex = promoContent.schedules.findIndex(el => el.id === scheduleId)

    setPromoContent(prevState => ({
      ...prevState,
      schedules: [
        ...prevState.schedules.slice(0, relevantScheduleIndex),
        {
          ...prevState.schedules[relevantScheduleIndex],
          intervals: [
            ...prevState.schedules[relevantScheduleIndex].intervals,
            {
              from: moment('2000-01-01 00:00','YYYY-MM-DD h:mm a'),
              to:   moment('2000-01-01 23:59','YYYY-MM-DD h:mm a')
            }
          ]
        },
        ...prevState.schedules.slice(relevantScheduleIndex + 1)
      ]
    }))
  }

  const deleteTimeRange = (scheduleId, index) => {
    const relevantScheduleIndex = promoContent.schedules.findIndex(el => el.id === scheduleId)

    setPromoContent(prevState => ({
      ...prevState,
      schedules: [
        ...prevState.schedules.slice(0, relevantScheduleIndex),
        {
          ...prevState.schedules[relevantScheduleIndex],
          intervals: [
            ...prevState.schedules[relevantScheduleIndex].intervals.slice(0, index),
            ...prevState.schedules[relevantScheduleIndex].intervals.slice(index + 1)
          ]
        },
        ...prevState.schedules.slice(relevantScheduleIndex + 1)
      ]
    }))
  }

  const draggableItemRenderer = (draggableAttributes, draggableListeners, item, index) => {
    return (
      <div key={ index } className='m-b-30 flex-box items-center g-2'>
        <div className='flex-box g-2'>
          <div className='m-r-20'>
            <i className='fa-light fa-grip-dots' style={{ cursor: 'grab' }} { ...draggableAttributes } { ...draggableListeners } />
          </div>
        </div>
        <div className='product-image-wrapper'>
          { /^.*image.*$/.test(item.mediaType)
            ? <img className='mini-image' src={ item.source } alt={ item.fileName } />
            : <div className='mini-image flex-box flex-column content-space-around items-center'>
                <i className='fa-light fa-file-video file-preview-type' style={{ fontSize: 30 }} />
                <div>
                  { item.fileName && `${ item.fileName.slice(0, 5) }${ item.fileName.length > 5 ? '...' : '' }` }
                </div>
              </div>
          }
        </div>
      </div>
    )
  }

  const save = _e => {
    if (mediaUploader.isUploading()) {
      setShowUploadAlert(true)
    } else {
      const fd = new FormData()

      fd.append('promo_content[name]', promoContent.name)
      fd.append('promo_content[rotation_period]', promoContent.rotationPeriod)
      fd.append('promo_content[promo_after_inactivity_minutes]', promoContent.promoAfterInactivityMinutes)
      fd.append('promo_content[active]', promoContent.active)

      promoContent.merchantSubaccounts.forEach((subaccountId, index) => {
        fd.append(`promo_content[merchant_subaccount_promo_contents_attributes][${ index }][merchant_subaccount_id]`, subaccountId)
      })

      promoContent.terminals.forEach((terminalId, index) => {
        fd.append(`promo_content[promo_content_terminals_attributes][${ index }][merchant_subaccount_id]`, (meraccTerminals ?? terminals).find(el => el.id === terminalId).merchantSubaccountId)
        fd.append(`promo_content[promo_content_terminals_attributes][${ index }][terminal_id]`, terminalId)
      })

      fd.append('assets', JSON.stringify(
        promoContent.assets.filter(asset => !asset.blob).map(asset => ({
          id: asset.id,
          media_id: asset.mediaId,
          uid: asset.uid
        }))
      ))

      if (assetOnMerchantAccount) {
        fd.append('asset_on_merchant_account', true)
      }

      promoContent.schedules.forEach((schedule, index) => {
        if (schedule.id > 0) {
          fd.append(`promo_content[schedules_attributes][${ index }][id]`, schedule.id)
        }

        if (schedule._destroy) {
          if (schedule.id > 0) {
            fd.append(`promo_content[schedules_attributes][${ index }][_destroy]`, true)
          }
        } else {
          fd.append(`promo_content[schedules_attributes][${ index }][day]`, schedule.day)
          fd.append(`promo_content[schedules_attributes][${ index }][intervals]`, JSON.stringify(schedule.intervals.map(el => ({ from: `${ el.from.hours() }:${ el.from.minutes() }`, to : `${ el.to.hours() }:${ el.to.minutes() }`}))))
          fd.append(`promo_content[schedules_attributes][${ index }][active]`, !!schedule.active)
        }
      })

      saveButtonRef.setSavingStatus()

      Rails.ajax({
        type: 'PATCH',
        url: updatePath,
        dataType: 'json',
        data: fd,
        success: res => {
          setPromoContent({
            ...res.promoContent,
            schedules: formatSchedules(res.promoContent.schedules)
          })

          saveButtonRef.setSuccessStatus()
        },
        error: res => {
          saveButtonRef.setErrorStatusWithMessage(res.error)
        }
      })
    }
  }

  return (
    <>
      <CreateButton
        pageTitle={ item.name }
        breadcrumbs={ appProps.breadcrumbs }
      />

      <div className='card'>
        <div className='card-body p-9'>
          <MediaGallery
            multiple
            mediaTypes={ ['image', 'video'] }
            files={ promoContent.assets }
            setFiles={ setAssets }
            onUploadMedia={ handleUploadAsset }
            mediaPath={ mediaPath }
            mediaUploader={ mediaUploader }
            chooseMediaTitle={ Website.translations.media.files }
          />

          { promoContent.assets.length > 0 &&
            <div className='m-t-20'>
              <div className='m-b-20'>{ Website.translations.mediaPositionHeading }</div>

              <VerticalDraggableItems
                items={ promoContent.assets.filter(asset => !asset.blob).map((el, index) => ({ ...el, id: `${ el.id ?? '' }-${ el.mediaId }`, position: el.position ?? index + 1 })) }
                itemRenderer={ draggableItemRenderer }
                setItems={ handleFileDrag }
                showDragOverlay={ false }
              />
            </div>
          }
        </div>
      </div>

      <div className='card m-t-30'>
        <FormInput
          label={ Website.translations.promoContentName }
          value={ promoContent.name }
          onChange={ e => handleChange('name', e) }
        />

        <FormInput
          label={ Website.translations.promoAfterInactivityMinutes }
          value={ promoContent.promoAfterInactivityMinutes }
          onChange={ e => handleChange('promoAfterInactivityMinutes', e) }
          type='number'
        />

        <FormInput
          label={ Website.translations.rotationPeriod }
          value={ promoContent.rotationPeriod }
          onChange={ e => handleChange('rotationPeriod', e) }
          type='number'
        />

        <div className='m-t-30 m-b-30'>
          <div className='fw-semibold form-label'>
            { Website.translations.schedule }
          </div>

          <div className='flex-box items-center content-space-between m-b-20'>
            <div className='flex-box content-end gap-4'>
              <label className='fs-6 fw-semibold form-label'>
                { Website.translations.scheduleDefault }
              </label>

              <Toggle
                icons={{ unchecked: null }}
                className='react-toggle small'
                checked={ !promoContent.schedules.length || promoContent.schedules.every(item => item._destroy) }
                onChange={ e => handleScheduleDefaultToggle(e.target.checked) }
              />
            </div>
          </div>

          { promoContent.schedules.some(item => !item._destroy) && dayArray.map(day => {
            const relevantSchedule = promoContent.schedules.find(el => el.day === day)

            return (
              <div
                key={ day }
                className={ 'p-l-5 m-b-20' }
                style={ day !== 'sunday' ? { borderBottom: '1px solid #eee' } : {} }
              >
                <div className='flex-box items-center gap-2 m-b-10'>
                  <Toggle
                    icons={{ unchecked: null }}
                    className='react-toggle small'
                    checked={ relevantSchedule?.active }
                    onChange={ e => handleScheduleDayToggle(day, e.target.checked) }
                  />

                  <div>{ Website.translations.days[day] }</div>
                </div>

                { renderScheduleIntervals(relevantSchedule) }
              </div>
            )
          })}
        </div>

        { canEditSubaccounts &&
          <FormInput
            label={ Website.translations.merchantSubaccounts }
            value={ promoContent.merchantSubaccounts }
            onChange={ e => handleChange('merchantSubaccounts', e) }
            type='select'
            multiple
            selectEntities={ merchantSubaccounts }
            keyAttr='id'
            valueAttr='id'
            outputAttr='internalName'
          />
        }

        <FormInput
          label={ Website.translations.terminals }
          value={ promoContent.terminals }
          onChange={ e => handleChange('terminals', e) }
          type='select'
          multiple
          selectEntities={ terminals.filter(item => promoContent.merchantSubaccounts.includes(item.merchantSubaccountId)) }
          keyAttr='id'
          valueAttr='id'
          outputAttr='sn'
        />

        <div className='flex-box content-end gap-4 m-t-20'>
          <label className='fs-6 fw-semibold form-label'>
            { Website.translations.scheduleActive }
          </label>

          <FormInput
            value={ promoContent.active }
            onChange={ e => handleChange('active', { target: { value: e.target.checked } }) }
            type='toggle'
          />
        </div>
      </div>

      { showUploadAlert &&
        <ModalAlert
          alert={ Website.translations.media.uploadAlert }
          cancelText={ Website.translations.close }
          onClose={ () => setShowUploadAlert(false) }
        />
      }

      <MediaUploader
        ref={ ref => setMediaUploader(ref) }
        mediaPath={ mediaPath }
      />

      <SaveFixedButton
        onSave={ save }
        entity={ Website.translations.promoContent }
        ref={ ref => setSaveButtonRef(ref) }
      />
    </>
  )
}
